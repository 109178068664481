$(document).ready(function () {
	var $main = $(".main"),
//			scrollCounter = -1,
			$typewriter = $('#second-section .typewriter-text p'),
			text = ' generations.',
//			text6 = 'IF YOU SEE THE EXPRESSION ‘POLISH DEATH CAMP’ USED IN AN ARTICLE OR ON SOCIAL MEDIA, SEND THIS LINK CORRECTMISTAKES.AUSCHWITZ.ORG TO THE AUTHOR TO HELP AVOID FALSIFYING HISTORY IN THE FUTURE.',
			$letters = $('.keyboard-letter'),
			animationInterval,
			prevIndex = 0;

	$(".main").onepage_scroll({
		sectionContainer: "section", // sectionContainer accepts any kind of selector in case you don't want to use section
		easing: "ease", // Easing options accepts the CSS3 easing animation such "ease", "linear", "ease-in", "ease-out", "ease-in-out", or even cubic bezier value such as "cubic-bezier(0.175, 0.885, 0.420, 1.310)"
		animationTime: 600, // AnimationTime let you define how long each section takes to animate
		pagination: true, // You can either show or hide the pagination. Toggle true for show, false for hide.
		updateURL: false, // Toggle this true if you want the URL to be updated automatically when the user scroll to each page.
		loop: false,
		beforeMove: function (index) {
			if (index === 2) {
				$('.keyboard-animation .letter-b strong').addClass('no-animation');
			}
		},
		afterMove: function (index) {


//			if (Number(index) > Number(prevIndex)) {
//				scrollCounter = -1;
//			} else {
//				scrollCounter = 2;
//			}

			prevIndex = index;

			if (index === 2) {
				window.setTimeout(function () {
					$('.keyboard-animation .letter-b strong').removeClass('no-animation');
				}, 300);

				if (!$('#second-section .typewriter').hasClass('animation-complete')) {

					$('.keyboard-animation').addClass('animation-started');
//					if (typeof animationInterval === 'undefined') {
					window.setTimeout(function () {

						var i = 0;

						if(typeof animationInterval === "undefined" || animationInterval === 0) {
							animationInterval = window.setInterval(function () {
								if (i < text.length) {
									if (i > 0) {
										$letters.filter("span[data-letter='" + text[i - 1].toLowerCase() + "']").removeClass('active');
									}

									$letters.filter("span[data-letter='" + text[i].toLowerCase() + "']").addClass('active');

									$typewriter.append(text[i]);
								} else {

									window.clearInterval(animationInterval);
									animationInterval = 0;
									window.setTimeout(function () {
										$('#second-section .typewriter').addClass('animation-complete');
									}, 200);

								}
								i++;
							}, 200);
						}

					}, 3600);
//					}
				}
			} else if (index === 5) {
				
//				if (!$('#sixth-section .typewriter').hasClass('animation-complete')) {
//
//					$('.keyboard-animation').addClass('animation-started');
////					if (typeof animationInterval === 'undefined') {
//					window.setTimeout(function () {
//
//						var i = 0;
//
//						animationInterval = window.setInterval(function () {
//							if (i < text6.length) {
//								if (i > 0) {
//									$letters.filter("span[data-letter='" + text6[i - 1].toLowerCase() + "']").removeClass('active');
//								}
//
//								$letters.filter("span[data-letter='" + text6[i].toLowerCase() + "']").addClass('active');
//
//								$('#sixth-section .typewriter-text p').append(text6[i]);
//							} else {
//
//								window.clearInterval(animationInterval);
//								window.setTimeout(function () {
//									$('#sixth-section .typewriter').addClass('animation-complete');
//								}, 200);
//
//							}
//							i++;
//						}, 100);
//
//					}, 600);
////					}
//				} 

				//count
				$('.counter h2 span').eq(0).each(function () {
					var $this = $(this),
						countTo = $this.parent().attr('data-count');

					
					$({countNum: $this.text()}).animate({
						countNum: countTo
					},
					{
						duration: 1000,
						easing: 'linear',
						step: function () {
							$this.text(Math.floor(this.countNum));
						},
						complete: function () {
							$this.text(this.countNum);
						}

					});
				});
			} else {
				if (!$('#second-section .typewriter').hasClass('animation-complete')) {
					$('#second-section .typewriter-text p').html('Help the words to convey the truth to the future');
				}

//				if (!$('#sixth-section .typewriter').hasClass('animation-complete')) {
//					$('#sixth-section .typewriter-text p').html('');
//				}

				window.clearInterval(animationInterval);
				animationInterval = 0;
				$('#second-section .typewriter').addClass('animation-started');
//				$('#sixth-section .typewriter').addClass('animation-started');
				$('.keyboard-animation').removeClass('animation-started');
				$letters.removeClass('active');
			}
			
			if(index !== 5) {
				$('.counter h2 span').eq(0).html('0');
			}
		}
	});

	$('.down a').on('click', function () {

//		if( Number($(this).parent().parent().data('index')) === 3 && scrollCounter < 2) {
//			scrollCounter++;
//			
//			if (scrollCounter <= 2) {
//				$('.scroll-effect').removeClass('current').eq(scrollCounter).addClass('current');
//			}
//
//			if (scrollCounter === 2) {
//				$('.scroll-effect').eq(2).addClass('current');
//				$('.scroll-effect').eq(3).addClass('current');
//			}
//			
//		} else {
			
			$main.moveDown();
//		}

		return false;
	});

	$('.up a').on('click', function () {
//		if( Number($(this).parent().parent().data('index')) === 3 && scrollCounter > -1) {
//			scrollCounter--;
//			
//			if (scrollCounter <= 2) {
//				$('.scroll-effect').removeClass('current').eq(scrollCounter).addClass('current');
//			}
//
//			if (scrollCounter === 2) {
//				$('.scroll-effect').eq(3).addClass('current');
//				$('.scroll-effect').eq(4).addClass('current');
//			}
//			
//		} else {
			$main.moveUp();
//		}

		return false;
	});
        
        $('.download a').on('click', function () {
            
            $main.moveTo(6);
            return false;
        });
        


//	$('#third-section').bind('mousewheel', function (e) {
//
//		if (e.originalEvent.wheelDelta / 120 > 0) {
//			scrollCounter--;
//
//			if (scrollCounter <= -1) {
//				$main.moveUp();
//			}
//		}
//		else {
//			scrollCounter++;
//			if (scrollCounter >= 3) {
//				$main.moveDown();
//			}
//		}
//
//		if (scrollCounter <= 2) {
//			$('.scroll-effect').removeClass('current').eq(scrollCounter).addClass('current');
//		}
//
//		if (scrollCounter === 2) {
//			$('.scroll-effect').eq(3).addClass('current');
//			$('.scroll-effect').eq(4).addClass('current');
//		}
//
//		return false;
//	});
//	
//	$('#third-section').bind('MozMousePixelScroll', function(e){
//		return false;
//	});
//
//	$('#third-section').bind('DOMMouseScroll', function (e) {
//		e.stopImmediatePropagation();
//
//		if (e.originalEvent.detail < 0) {
//			scrollCounter--;
//
//			if (scrollCounter <= -1) {
//				$main.moveUp();
//			}
//		}
//		else {
//			scrollCounter++;
//			if (scrollCounter >= 3) {
//				$main.moveDown();
//			}
//		}
//
//		if (scrollCounter <= 2) {
//			$('.scroll-effect').removeClass('current').eq(scrollCounter).addClass('current');
//		}
//
//		if (scrollCounter === 2) {
//			$('.scroll-effect').eq(3).addClass('current');
//		}
//
//		return false;
//	});


	window.setTimeout(function () {
		$('body').removeClass("loading").addClass('loaded');
	}, 2000);


});
